<template>
  <div>
    <header class="w-full relative">
      <div class="main-container flex flex-col items-center justify-between relative">
        <div class="flex flex-col md:flex-row aic jcb w-full md:h-32 z-50">
          <div class="hidden md:block absolute left-0 top-12 w-full border-b h-10p"></div>
          <nav class="hidden md:flex ais jcs h-full z-1 merienda-regular">
            <router-link
              to="/"
              class="md:text-xs xl:text-sm a-secondary md:px-5 xl:px-6 relative h-full flex aie jcc pb-5"
              :class="activeRoute('/') ? 'bg-brown-lighter shadow-2xl' : ''"
            >
              <h3 class="uppercase">{{$t('Home')}}</h3>
            </router-link>
            <router-link
              to="/about"
              class="md:text-xs xl:text-sm whitespace-nowrap a-secondary md:px-5 xl:px-6 relative h-full flex aie jcc pb-5"
              :class="activeRoute('/about') ? 'bg-brown-lighter shadow-2xl' : ''"
            >
              <h3 class="uppercase">{{$t('About us')}}</h3>
            </router-link>
            <router-link
              to="/services"
              class="md:text-xs xl:text-sm a-secondary md:px-5 xl:px-6 relative h-full flex aie jcc pb-5"
              :class="activeRoute('/services') ? 'bg-brown-lighter shadow-2xl' : ''"
            >
              <h3 class="uppercase">{{$t('How it works')}}</h3>
            </router-link>
            <router-link
              to="/blogs"
              class="md:text-xs xl:text-sm a-secondary md:px-5 xl:px-6 relative h-full flex aie jcc pb-5"
              :class="activeRoute('/blogs') ? 'bg-brown-lighter shadow-2xl' : ''"
            >
              <h3 class="uppercase">{{$t('Blogs')}}</h3>
            </router-link>
            <button
              class="md:text-xs xl:text-sm a-secondary md:px-5 xl:px-6 relative h-full flex aie jcc pb-5"
              @click="showContact = true"
            >
              <h3 class="uppercase">{{$t('Contact')}}</h3>
            </button>
          </nav>
          <div class="flex flex-col items-center md:items-end space-y-4 mt-6 lg:mt-8">
            <div class="hidden md:flex aic jcs">
              <router-link
                to="/dashboard"
                class="flex aic text-secondary text-sm merienda-regular"
              >
                <!-- <small>Rayan Khaleghi</small> -->
                <small class="whitespace-nowrap">{{$t('Your Account')}}</small>
                <div class="ml-2 lg:ml-4 text-brown-lightest">
                  <svg
                    class="h-4 w-4"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M19 7.001c0 3.865-3.134 7-7 7s-7-3.135-7-7c0-3.867 3.134-7.001 7-7.001s7 3.134 7 7.001zm-1.598 7.18c-1.506 1.137-3.374 1.82-5.402 1.82-2.03 0-3.899-.685-5.407-1.822-4.072 1.793-6.593 7.376-6.593 9.821h24c0-2.423-2.6-8.006-6.598-9.819z" />
                  </svg>
                </div>
              </router-link>
              <!-- <lang-switcher></lang-switcher> -->
            </div>
            <img
              src="/img/logo.webp"
              alt="Logo"
              class="hidden md:block w-20 lg:w-24"
              width="300"
              height="220"
            >
          </div>
          <!-- <img src="/img/logo.webp" alt="#" class="w-20 sm:w-28 md:w-32 mt-16 md:-mt-12 lg:mt-0"> -->
        </div>
      </div>
    </header>
    <NewContact
      :showContact="showContact"
      @close="showContact = false"
    ></NewContact>
    <Probe
      :showProbeModal="showProbeModal"
      @close="showProbeModal = false"
    >
    </Probe>
    <button
      class="probe-fix z-1 bg-secondary min-w-52 px-5 h-[65px] shadow-2xl hidden md:flex"
      :class="[scrollProbe ? 'show' : 'hide']"
      @click="showProbeModal = true"
      aria-label="Probe Modal"
    >
      <span class="relative h-full w-full flex aic jcc text-white space-x-4">
        <svg xmlns="http://www.w3.org/2000/svg" class="w-7 h-7" fill="currentColor" viewBox="0 0 24 24">
          <path d="M11 24h-9v-12h9v12zm0-18h-11v4h11v-4zm2 18h9v-12h-9v12zm0-18v4h11v-4h-11zm4.369-6c-2.947 0-4.671 3.477-5.369 5h5.345c3.493 0 3.53-5 .024-5zm-.796 3.621h-2.043c.739-1.121 1.439-1.966 2.342-1.966 1.172 0 1.228 1.966-.299 1.966zm-9.918 1.379h5.345c-.698-1.523-2.422-5-5.369-5-3.506 0-3.469 5 .024 5zm.473-3.345c.903 0 1.603.845 2.342 1.966h-2.043c-1.527 0-1.471-1.966-.299-1.966z"/>
        </svg>
        <!-- <img src="/img/gift-1.webp" alt="probe" class="absolute left-5 w-60p -mt-2" width="300" height="287"> -->
        <span class="text-left merienda-bold tracking-widest text-white text-sm whitespace-nowrap">GRATIS PROBIEREN</span>
      </span>
    </button>
    <button
      class="fixed right-8 bottom-14 z-[60] bg-secondary w-[50px] h-[50px] shadow-2xl md:hidden rounded-lg"
      :class="[scrollProbe ? 'show' : 'hide']"
      @click="showProbeModal = true"
      aria-label="Probe Modal"
    >
    <span class="relative h-full w-full flex aic jcc text-white space-x-4">
      <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
        <path d="M11 24h-9v-12h9v12zm0-18h-11v4h11v-4zm2 18h9v-12h-9v12zm0-18v4h11v-4h-11zm4.369-6c-2.947 0-4.671 3.477-5.369 5h5.345c3.493 0 3.53-5 .024-5zm-.796 3.621h-2.043c.739-1.121 1.439-1.966 2.342-1.966 1.172 0 1.228 1.966-.299 1.966zm-9.918 1.379h5.345c-.698-1.523-2.422-5-5.369-5-3.506 0-3.469 5 .024 5zm.473-3.345c.903 0 1.603.845 2.342 1.966h-2.043c-1.527 0-1.471-1.966-.299-1.966z"/>
      </svg>
    </span>
    </button>

  </div>
</template>
<script>
// import LangSwitcher from "../components/LangSwitcher.vue";
import NewContact from "../components/NewContact.vue";
import Probe from "../components/Probe.vue";

export default {
  components: { NewContact, Probe },
  data() {
    return {
      scrollProbe: false,
      showProbeModal: false,
      showContact: false,
    };
  },
  methods: {
    activeRoute(path) {
      return (
        this.$route.path === path ||
        this.$route.path.split("/")[1] == path.split("/")[1]
      );
    },
    scrollFunc() {
      let y = window.scrollY;

      if (y > 500) {
        this.scrollProbe = true;
      }
      if (y < 500) {
        this.scrollProbe = false;
      }
    },
  },
  created() {
    window.addEventListener("scroll", this.scrollFunc);
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollFunc);
  },
};
</script>

<template>
    <div class="relative overflow-x-hidden">
        <InnerHeader />
        <div class="flex flex-col lg:flex-row ais jcb main-container pt-6 lg:border-t border-gray-100 relative px-10 md:px-0 md:mt-8 lg:mt-14">
            <!-- <div class="absolute right-0 top-0 h-10p w-80p"></div> -->
            <h1 class="min-w-max lg:text-xl xl:text-2xl merienda-bold uppercase order-2 md:order-1 mt-2 md:mt-0 text-brown-light">{{$t('About us')}}</h1>
            <ol class="min-w-max flex aic space-x-3 text-xs order-1 md:order-2">
                <li><router-link to="/" class="nunitoSans-regular text-gray-500">{{$t('Home')}}</router-link></li>
                <li class="text-gray-400"><i class="fa fa-angle-right"></i></li>
                <li class="nunitoSans-bold">{{$t('About us')}}</li>
            </ol>
        </div>
        <div class="main-container mt-8 -mb-20 flex flex-wrap ais px-8 md:px-0">
            <div class="w-full relative flex flex-col">
                <div class="flex flex-col xl:flex-row jcb w-full relative overflow:visible px-2 lg:px-12 xl:px-0">
                    <div class="flex flex-col jcb w-full xl:w-6/12 service-main-img order-2 xl:order-1 mt-12">
                        <img src="img/about-top.webp" alt="#" class="w-full max-w-md xl:max-w-2xl mx-auto xl:mx-0">
                    </div>
                    <div class="relative flex flex-col jcs w-full xl:w-5/12 xl:order-1">
                        <div class="flex flex-col">
                            <h2 class="text-2xl lg:text-3xl text-gray-900 comfortaa-regular text-center xl:text-left px-4 xl:px-0 italic ">
                                Über
                                <br>
                                <b class="text-3xl lg:text-4xl merienda-bold text-brown-light">Brötchen Shop</b>
                            </h2>
                            <!-- <div class="ub-2 mt-10 mid-shadow">
                            </div> -->
                        </div>
                        <p class="mt-10 text-gray-600 text-sm lg:text-base leading-5 mx-auto Exo2-Light mb-auto">
                            Kennst du das auch? Morgens 9 Uhr, du hast Hunger und vor der Bäckerei steht eine riesen Schlange? Endlich an der 
                            Reihe hat der Kunde vor dir das letzte Lieblingsbrötchen bestellt. Nachschub gibt es nicht oder du musst auf 20 Minuten auf die nächste Fuhre warten?
                            Du hast keine Lust mehr zu warten oder auf Ersatzprodukte auszuweichen?
                            <br>
                            <br>
                            Unser Brötchenlieferservice liefert Ihnen frische Backwaren örtlicher Bäckereien morgens an Ihre Haustür. Wir liefern frisch gebackene Brötchen und andere Backwaren still und leise morgens an Ihre Haustür. Wir führen Ihre Bestellung immer bis auf Widerruf aus. In dem wir traditionelle Handwerkskunst mit moderner Technik verbinden werden unsere Backwaren höchsten Standards gerecht.
                            Morgens frische Backwaren an der Haustür zu haben bietet ein enormes Stück Lebensqualität. Den Sonntag beim gemeinsamen Frühstück mit der Familie zu beginnen oder den Kindern wochentags frische Brötchen mit zur Schule zu geben ohne vorher das Haus verlassen zu müssen.
                        </p>
                    </div>
                </div>
                <div class="flex flex-col xl:flex-row ais jcb w-full relative overflow:visible px-2 lg:px-12 mt-12 ">
                    <div class="relative flex flex-col jcs w-full xl:w-5/12 xl:order-1">
                        <p class="text-gray-600 text-sm lg:text-base leading-5 mx-auto Exo2-Light mb-auto">
                            Eine wesentliche Zutat für den Erfolg: Alle Backwaren werden täglich frisch gebacken – von sorgfältig ausgewählten Handwerksbäckern aus der jeweiligen Region.
                            <br>
                            <br>
                            <b class="merienda-bold text-brown">Wir wünsche guten Appetit!</b>
                        </p>
                    </div>
                    <div class="flex flex-col jcb w-full xl:w-4/12 service-main-img order-2 xl:order-1 relative xl:mb-16">
                        <svg width="204" height="304" fill="none" viewBox="0 0 204 304" class="absolute -top-20 -right-5 -z-1">
                            <defs>
                                <pattern id="837c3e70-6c3a-44e6-8854-cc48c737b659" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                                    <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                                </pattern>
                            </defs>
                            <rect width="204" height="304" fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)" />
                        </svg>
                        <img src="img/about-btm.webp" alt="#" class="w-full my-16 xl:my-0 max-w-md mx-auto xl:mx-0">
                    </div>
                </div>
            </div>
        </div>
        <MainFooter />
    </div>
</template>
<script>
import InnerHeader from "../components/InnerHeader.vue"
import MainFooter from "../components/MainFooter.vue"
export default {
    components: { InnerHeader, MainFooter }
};
</script>
